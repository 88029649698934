import React, { Component } from "react";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import { Card, Form, Input } from "../componets/AuthForm";
import "../App.css";
import styled from "styled-components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Button = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${props => (props.primary ? "palevioletred" : "white")};
  color: ${props => (props.primary ? "white" : "palevioletred")};

  font-size: 1em;
  margin: 1em 0;
  padding: 0.25em 1em;
  border: 2px solid palevioletred;
  border-radius: 3px;
`;

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      isNotAuthorized: true
    };
    this.validator = new SimpleReactValidator();
    this.onChangEmail = this.onChangEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChangEmail(e) {
    this.setState({ email: e.target.value });
  }

  onChangePassword(e) {
    this.setState({ password: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();
    const { email, password } = this.state;
    const activeUser = localStorage.getItem("users");
    const parseData = activeUser ? JSON.parse(activeUser) : [];
    const currentUserData = parseData.find(i => i.email === email);

    if (this.validator.allValid()) {
      if (currentUserData && currentUserData.password === password) {
        this.setState({
          isNotAuthorized: false
        });

        toast.success("Login Successful!", {
          position: toast.POSITION.TOP_CENTER
        });
        setTimeout(() => {
          this.props.history.push("/trellopage");

          window.location.reload();

          localStorage.setItem("CurrentUser", JSON.stringify(currentUserData));
        }, 3000);
      } else {
        toast.error("Invalid Credentials!", {
          position: toast.POSITION.TOP_CENTER
        });
      }
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  render() {
    return (
      <div className="mt-150">
        <Card>
          <Form>
            <div className="form-group">
              <Input
                className="form-control"
                type="text"
                value={this.state.email}
                onChange={this.onChangEmail}
                placeholder="email"
              />

              {this.validator.message(
                "Email",
                this.state.email,
                "required|email"
              )}
            </div>
            <div className="form-group">
              <Input
                className="form-control"
                type="password"
                value={this.state.password}
                onChange={this.onChangePassword}
                placeholder="password"
              />

              {this.validator.message(
                "Password",
                this.state.password,
                "required"
              )}
            </div>
            <Button onClick={this.onSubmit}>Sign In</Button>
          </Form>
          <Link to="/signup">Don't have an account?</Link>
          <ToastContainer />
        </Card>
      </div>
    );
  }
}
