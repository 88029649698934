import React from "react";
import "../App.css";
import styled from "styled-components";

const Title = styled.h1`
  font-size: 1.5em;
  text-align: center;
  color: #343c44;
`;

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.section`
  padding: 4em;
  background: papayawhip;
`;

function Home(props) {
  return (
    <div className="home-wrapper mt-150">
      <Wrapper>
        <Title>Please Login or Sign Up to Continue</Title>
      </Wrapper>
    </div>
  );
}

export default Home;
