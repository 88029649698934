import React, { useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from "./pages/Home";
import Navbar from "./componets/Navbar";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import TrelloPage from "./pages/TrelloPage";
import { AuthContext } from "./context/auth";

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

function App(props) {
  return (
    <Router>
      <Navbar />
      <div>
        <Route exact path="/" component={Home} />
        <Route path="/login" component={Login} />
        <Route path="/signup" component={Signup} />
        <Route path="/trellopage" component={TrelloPage} />
      </div>
    </Router>
  );
}

export default App;
