import React from "react";
import Board from "react-trello";

const data = {
  lanes: [
    {
      id: "board1",
      title: "Planned Tasks",
      cards: [
        {
          id: "Card1",
          title: "Card1 Title",
          description: "Card1 Description",
          label: "30 mins",
          draggable: true
        },
        {
          id: "Card2",
          title: "Card2 Title",
          description: "Card2 Description",
          label: "5 mins",
          metadata: { sha: "be312a1" }
        }
      ]
    },
    {
      id: "board2",
      title: "Finished Tasks",
      cards: [
        {
          id: "Card3",
          title: "Card3 Title",
          description: "Card3 Description",
          label: "30 mins",
          draggable: true
        }
      ]
    },
    {
      id: "board3",
      title: "Work In Progress",
      cards: [
        {
          id: "Card4",
          title: "Card4 Title",
          description: "Card4 Description",
          label: "30 mins",
          draggable: true
        }
      ]
    },
    {
      id: "board4",
      title: "Upcoming",
      cards: [
        {
          id: "Card5",
          title: "Card5 Title",
          description: "Card5 Description",
          label: "30 mins",
          draggable: true
        }
      ]
    }
  ]
};

export default class App extends React.Component {
  render() {
    return (
      <>
        <Board
          style={{ backgroundColor: "white" }}
          data={data}
          editable={true}
          canAddLanes
          editLaneTitle
        />
        ;
      </>
    );
  }
}
