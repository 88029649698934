import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Card, Form, Input } from "../componets/AuthForm";
import SimpleReactValidator from "simple-react-validator";
import "../App.css";
import styled from "styled-components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Button = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${props => (props.primary ? "palevioletred" : "white")};
  color: ${props => (props.primary ? "white" : "palevioletred")};

  font-size: 1em;
  margin: 1em 0;
  padding: 0.25em 1em;
  border: 2px solid palevioletred;
  border-radius: 3px;
`;

export default class Signup extends Component {
  userData;
  constructor(props) {
    super(props);

    this.state = {
      email: undefined,
      password: undefined,
      name: undefined,
      signup: localStorage.getItem("newUser")
        ? JSON.parse(localStorage.getItem("newUser"))
        : []
    };

    this.validator = new SimpleReactValidator();
    this.onChangEmail = this.onChangEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangedName = this.onChangedName.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  // Form Values
  onChangEmail(e) {
    this.setState({ email: e.target.value });
  }

  onChangePassword(e) {
    this.setState({ password: e.target.value });
  }

  onChangedName(e) {
    this.setState({ name: e.target.value });
  }

  onSubmit = async e => {
    e.preventDefault();
    const { name, email, password, signup } = this.state;
    if (this.validator.allValid()) {
      toast.success("Sign Up Successful!", {
        position: toast.POSITION.TOP_CENTER
      });
      setTimeout(() => {
        let j = [];
        j.push({
          email,
          password,
          name
        });

        this.setState(
          {
            signup: [...signup, ...j]
          },
          () => {
            this.props.history.push("/login");
            localStorage.setItem("users", JSON.stringify(this.state.signup));
          }
        );
      }, 3000);
    } else {
      this.validator.showMessages();
      // rerender to show messages for the first time
      this.forceUpdate();
    }
  };

  render() {
    return (
      <div className="mt-150">
        <Card>
          <Form>
            <div className="form-group">
              <Input
                className="form-control"
                type="text"
                placeholder="Name"
                value={this.state.name}
                onChange={this.onChangedName}
              />

              {this.validator.message("Name", this.state.name, "required")}
            </div>

            <div className="form-group">
              <Input
                className="form-control"
                type="email"
                value={this.state.email}
                onChange={this.onChangEmail}
                placeholder="Email"
              />

              {this.validator.message(
                "Email",
                this.state.email,
                "required|email"
              )}
            </div>

            <div className="form-group">
              <Input
                className="form-control"
                type="password"
                placeholder="Password"
                value={this.state.password}
                onChange={this.onChangePassword}
              />

              {this.validator.message(
                "Password",
                this.state.password,
                "required|min:8|max:30"
              )}
            </div>
            <Button type="submit" onClick={this.onSubmit}>
              Sign Up
            </Button>
          </Form>
          <Link to="/login">Already have an account?</Link>
          <ToastContainer autoClose={false} />
        </Card>
      </div>
    );
  }
}
