import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../App.css";
import styled from "styled-components";

const Button = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${props => (props.primary ? "palevioletred" : "white")};
  color: ${props => (props.primary ? "white" : "palevioletred")};

  font-size: 1em;
  margin: 1em;
  padding: 0.25em 1em;
  border: 2px solid palevioletred;
  border-radius: 3px;
`;

const Public = props => {
  const [Login, setLogin] = useState(true);

  useEffect(() => {
    const a = localStorage.getItem("CurrentUser") ? true : false;
    setLogin(a);
  }, []);

  const logoutUSer = () => {
    localStorage.removeItem("CurrentUser");
    setLogin(false);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
      }}
    >
      <div></div>
      <div className="nav">
        <ul
          style={{
            listStyleType: "none",
            display: "flex",
            flexDirection: "row"
            // margin: 50
          }}
        >
          {!Login ? (
            <>
              <li>
                <Link to="/login">
                  <Button>Login</Button>
                </Link>
              </li>
              <li>
                <Link to="/signup">
                  <Button>Sign Up</Button>
                </Link>
              </li>
            </>
          ) : (
            <>
              <li>
                <Link to="/" onClick={logoutUSer}>
                  <Button>Logout</Button>
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
};
export default Public;
